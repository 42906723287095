import '../styles/About.css'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { Stack } from 'react-bootstrap'

import Ira from '../assets/photos/Ira_Pod_Final_Square_RG 1.png'

const About = () => {
    return (
        <Stack fluid>
            <Image
                src={Ira}
                alt='Ira'
                className='about-photo'
            />
            <Container>
                <h2 className='about-title'>About Ira</h2>
                <p className='about-bio' >Ira Elliot is a life-long Beatlephile. 
                    Born in March of 1963, actually within hours of the release of the Beatles first 
                    album “Please Please Me.” The Beatles, their lawyers, his own parents and their lawyers 
                    have continued to deny that this was intentional. He’s hardly a scholar and is loathe 
                    to refer to himself as an expert, but let’s just say he <i>probably</i> knows more about 
                    the Beatles than you.</p>
                <p className='about-bio' >Raised in Forest Hills, Queens and a graduate of New York City’s 
                    Fiorello LaGuardia High School of the Performing Arts, an early member of 80s garage rock 
                    legends The Fuzztones, a roadie for The Smithereens, a member of Nada Surf since 1995, 
                    a founding member of Bambi Kino in 2010 and a podcaster for like, ten minutes now, maybe.</p>
                <p className='about-bio' >Ira presently lives in sunny Sarasota, Florida. 
                    He has a beautiful family and amassed a crap ton of incredibly great friends. He is grateful.</p>
            </Container>
        </Stack>
    )
}

export default About