import Navbar from "./Navbar"
import Footer from "./Footer"
import { Outlet } from 'react-router-dom'
import '../styles/Root.css'
import { useState } from "react"

import { Stack } from "react-bootstrap"
import Header from "./Header"


const Root = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleSidebarSet = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Stack fluid>
            <Header />
            {/* <Navbar openSetter={handleSidebarSet} /> */}
            <div id='detail'>
                <Outlet />
            </div>
            <Footer />
        </Stack>
    )
}

export default Root

// < div className = "body" >
//     <Sidebar openSetter={handleSidebarSet} isOpen={isOpen} />
//     </div >