import { Container } from "react-bootstrap"
import '../styles/Header.css'

const Header = () => {
    return (
        <Container className="header-bar" fluid>
            <h1 className="header-text text-center">
                <a
                className="header-text" 
                    href="https://aharddayslatenightwithira.podbean.com/e/a-hard-days-late-night-with-ira-episode-1-danceteria/">
                Listen to Episode 1 - Danceteria!
                </a>
                </h1>
        </Container>
    )
}

export default Header