import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


import Home from './components/Home';
import ErrorPage from './components/ErrorPage';
import About from './components/About'
import Root from './components/Root';
import Episodes from './components/Episodes';

import 'bootstrap/dist/css/bootstrap.min.css'

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  HashRouter,
  Routes,
  createHashRouter
} from "react-router-dom"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage/>}>
      <Route path="/" element={<Home/>}/>
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path='/episodes' element={<Episodes />}/>
    </ Route>

  )
)

// const router = createHashRouter(
//   createRoutesFromElements(
//     <Route path='/' element={<Root/>}>
//       <Route path='/about' element={<About />} />
//       <Route path='/' element={<Home />} />
      
//     </Route>
//   )
// )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
