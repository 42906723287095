import { Stack } from 'react-bootstrap'
import '../styles/Footer.css'
import Nav from 'react-bootstrap/Nav'

const Footer = () => {
    return (
        // <Container className="footer-bar" fluid>
        <Stack id='footer-stack' gap={0}>
             <Nav>
                <Nav.Item>
                    <Nav.Link href="/" >
                        <p className='footer-link'> Home </p> </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/about" >
                        <p className='footer-link'> About </p> </Nav.Link>
                </Nav.Item>
                {/*<Nav.Item>
                    <Nav.Link href='mailto:aharddayslatenightwithira@gmail.com' className='footer-link'>
                        <p className='footer-link'> Contact </p>  </Nav.Link>
                </Nav.Item>*/}
            </Nav> 
            <p className='footer-text'>Copyright AHDLNWI 2024</p>
        </Stack>
        //</Container>
    )
}

export default Footer