// import '../styles/Episodes.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

const Episodes = () => {
    return (
        <Container fluid>
            <h1>Episodes</h1>
            <Col>
            </Col>
        </Container>
    )
}

export default Episodes